
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































































































.meter,
[class*='meter--'] {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
  padding: 2.5rem 2rem;
  background: $c-white;
  border: 1px solid $c-gray-lighter;
  border-radius: 20px;
  transition: box-shadow 0.2s;

  &:hover:not([class*='--no-hover']) {
    @extend %shadow;

    cursor: pointer;

    ::v-deep .btn__icon {
      transform: translateX(5px);
    }
  }

  &.is-submitted {
    pointer-events: none;
  }

  @include mq(s) {
    display: flex;
    padding: 3rem 4rem;
  }

  @include mq(xxl) {
    padding: 3rem 7rem;
  }
}

.header {
  display: flex;
  margin-bottom: 1.5rem;
  line-height: 1.2;

  @include mq(xxl) {
    margin-bottom: 3rem;
  }
}

.header__icon {
  width: 3.8rem;
  height: 3.8rem;
  margin: 0 0.5rem 0 -1rem;

  &.elec {
    fill: $c-main-secondary;
  }

  &.gaz {
    fill: $c-orange;
  }

  &.propane {
    fill: $c-blue;
  }

  @include mq(s) {
    width: 4.8rem;
    height: 4.8rem;
    margin: 0 0.5rem 0 -1.5rem;
  }
}

.header__headline {
  .is-submitted & {
    color: $c-green;
  }

  [class*='meter--'][class*='--generic'] & {
    display: none;
  }
}

.header__title {
  @extend %fw-bold;
  @include fluid-type(18, 24);
}

.infos {
  margin-left: 3rem;

  @include mq(s) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-left: 4rem;
  }
}

.infos__meter__ids,
.infos__meter__dates {
  margin-bottom: 2rem;

  @include mq(s) {
    margin-bottom: 0;
    margin-left: 4rem;
  }
}

.infos__meter {
  flex: 1;
}

.infos__meter__ids {
  flex-shrink: 0;
  margin-right: 1rem;
  margin-left: 0;
  color: $c-gray;
}

.infos__meter__ids__list {
  display: flex;

  span {
    margin-left: 0.5rem;
  }
}
