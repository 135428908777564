
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



















































































































.title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.title__cta {
  font-size: 15px;
  font-family: $ff-default;
}

.title__label {
  margin-right: 1rem;
  line-height: 1;
}

.ean__item {
  &:not(:last-child) {
    border-bottom: 1px solid $c-gray-lighter;
  }
}

.ean__item__pin {
  // prettier-ignore
  @include fluid(width, (xxs: 36px, xxl: 50px));
  // prettier-ignore
  @include fluid(height, (xxs: 36px, xxl: 50px));
  // prettier-ignore
  @include fluid(margin-right, (xxs: 10px, xxl: 20px));

  flex-shrink: 0;
  fill: $c-orange;
}

.ean__item__address {
  line-height: 1;
}

.ean__item__links {
  > * {
    margin: 0.5rem;
  }
}
